.modals-questions {
  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hover-grab {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}
